import L from 'leaflet';
// Create a custom icon
const MarkerIcon2 = L.icon({
  iconUrl: require('../images/marker2.png'),
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});


export default MarkerIcon2
