import L from 'leaflet';
// Create a custom icon
const MarkerIcon3 = L.icon({
  iconUrl: require('../images/marker3.png'),
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});


export default MarkerIcon3
